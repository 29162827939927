import AppleStore from "../assets/redes/apple.webp";
import AppStore from "../assets/redes/app_store.webp";
import "../styles/button.css";
export const DownloadButtonGoogle = ({ secodary = false }) => {
  return (
    <a
      href="https://play.google.com/store/apps/details?id=com.unitedwom.com"
      className={`btn ${secodary ? "color-2" : "color-1"}`}
    >
      <div className="d-flex justify-content-between align-items-center gap-2">
        <p className="no-margin t-button">Descarga</p>
        <img
          loading="lazy"
          src={AppStore}
          alt="AppStore"
          width="100%"
          height="100%"
        />
      </div>
    </a>
  );
};
export const DownloadButtonApple = ({ secodary = false }) => {
  return (
    <a
      href="https://apps.apple.com/es/app/unitedwom/id1625720092"
      className={`btn ${secodary ? "color-2" : "color-1"}`}
    >
      <div className="d-flex justify-content-between align-items-center gap-2">
        <p className="no-margin t-button">Descarga</p>
        <img
          loading="lazy"
          src={AppleStore}
          alt="AppleStore"
          width="100%"
          height="100%"
        />
      </div>
    </a>
  );
};
export const DownloadButtonText = () => {
  return (
    <button className="btn color-1 ">
      <div className="d-flex justify-content-between">
        <p className="no-margin">¡Descarga ya la app!</p>
      </div>
    </button>
  );
};
export const MoreButton = () => {
  return (
    <a className="btn-2 color-3" href="#contacto">
      <div className="d-flex justify-content-between">
        <p className="no-margin">Saber más</p>
      </div>
    </a>
  );
};
