import { motion } from "framer-motion";
import { useState } from "react";
import check from "../assets/iconos/checkgreen.webp";
import descuentos from "../assets/iconos/descuentosgreen.webp";
import Colaboraciones from "../assets/iconos/difusiongreen.webp";
import embajadora from "../assets/iconos/evento.webp";
import ingresosextras from "../assets/iconos/ingresosextras.webp";
import Img from "../assets/img/ambassadress_bg.webp";
import "../styles/legacy.css";

const INFOLEGACY = [
  {
    image: embajadora,
    texts: "Embajadoras",
    description:
      "¿Te gusta nuestra idea? ¿Te apetece formar parte? Estamos encantadas de recibirte en nuestro equipo. El programa embajadoras está dirigido a todas aquellas mujeres que se sientan identificadas con nuestros valores y que quieran pertenecer para desarrollar una labor de forma activa. ",
  },
  {
    image: descuentos,
    texts: "Descuentos",
    description:
      "Si te apetece asistir a alguna de las actividades propuestas en UW, podrás hacerlo con beneficios por ser embajadora.",
  },
  {
    image: ingresosextras,
    texts: "Ingresos Extra",
    description:
      "Crea tu propia cartera de partners para obtener los ingresos extra que quieras. El límite lo pones tú. ",
  },
  {
    image: Colaboraciones,
    texts: "Colaboraciones",
    description:
      "Podrás asistir a reuniones on-line con el equipo para escuchar o aportar ideas. Tu opinión nos importa porque Juntas Sumamos. Si te apetece dar visibilidad a tu negocio, a tu idea, apoyar a las mujeres de la manera que esté a tu alcance, colaborar con una idea nueva basada en la conexión para Sumar…. ¡Cuenta con nosotras! Elegirse es Invertir en Ti.",
  },
];

const Legacy = () => {
  const [show, setShow] = useState(true);

  return (
    <section id="legacies" className="legacy-height purple-green-background">
      <div className="legacy-container">
        <img loading="lazy" src={Img} alt="legacy" width="100%" height="100%" />
      </div>
      <div className="container container-padding h-100">
        <div className="legacyTitle">
          <h1 className="display-4">Embajadoras</h1>
        </div>
        <div className="h-100 d-flex align-items-md-end">
          <div className="w-100">
            <div className="card-container w-100">
              {INFOLEGACY.map((e, i) => (
                <LegacyCard
                  key={i}
                  index={i}
                  show={show}
                  setShow={() => setShow(show === i ? false : i)}
                  icon={e.image}
                  text={e.texts}
                  description={e.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const LegacyCard = ({ icon, text, description, index, show, setShow }) => {
  const [rotate, setRotate] = useState(false);
  return (
    <div className="legacy-card">
      <motion.div
        className="d-flex justify-content-center card-img-green-container"
        whileTap={{ scale: 0.9 }}
      >
        <button onClick={() => setShow()} className="button">
          {show === index ? (
            index
          ) : (
            <img
              loading="lazy"
              src={icon}
              alt="legacyimage"
              width="73px"
              height="70px"
            />
          )}
          {show === index && (
            <>
              <motion.p
                className="t-green t-description"
                whileHover={{ scale: 1.05 }}
              >
                {description}
              </motion.p>
              <motion.div
                className="d-flex justify-content-center card-icon-green-container-down"
                animate={{ rotate: rotate ? 360 : 0 }}
                transition={{ duration: 1 }}
                onClick={() => {
                  setRotate(!rotate);
                }}
              >
                <img
                  loading="lazy"
                  src={check}
                  alt="check"
                  width="60px"
                  height="45px"
                />
              </motion.div>
            </>
          )}
          {show !== index && (
            <>
              <div className="d-flex align-items-center card-text-green-container">
                <p className="t-green text-center w-100">{text}</p>
              </div>
              <div className="d-flex justify-content-center card-icon-green-container">
                <img
                  loading="lazy"
                  src={check}
                  alt="check"
                  width="60px"
                  height="45px"
                />
              </div>
            </>
          )}
        </button>
      </motion.div>
    </div>
  );
};
export default Legacy;
