/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-has-content */
import "../styles/scroll.css";
import { useScrollSpy } from "./useScrollSpy";

const Scroll = () => {
  const { slideStyle } = useScrollSpy();
  return (
    <div id="scroll-spy nav-pills" className="scroll">
      <div className="tabs w-100 h-100 d-flex flex-column justify-content-center align-items-center">
        <a
          className="tabs point nav-link"
          aria-label="Lee mas acerca de unitedwom en homr"
          href="#home"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Lee mas acerca de unitedwom en nosotras hacemos"
          href="#nosotras_hacemos"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Lee mas acerca de unitedwom en categorias"
          href="#categorias"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Lee mas acerca de unitedwom en asociadas"
          href="#asociados"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Lee mas acerca de unitedwom en embajadoras"
          href="#legacies"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Lee mas acerca de unitedwom en nuestra historia"
          href="#nuestra_historia"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Conoce mas acerca de unitedwom media "
          href="#media"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Lee mas acerca de unitedwom en preguntas frecuentes"
          href="#preguntas_frecuentes"
        ></a>
        <a
          className="tabs point nav-link"
          aria-label="Contáctenos en en el siguiente apartado"
          href="#contacto"
        ></a>
      </div>
    </div>
  );
};

export default Scroll;
