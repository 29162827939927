import { motion } from "framer-motion";
import { useState } from "react";
import apoyo from "../assets/iconos/apoyo.webp";
import check from "../assets/iconos/check.webp";
import community from "../assets/iconos/community.webp";
import difusion from "../assets/iconos/difusion.webp";
import networking from "../assets/iconos/networking.webp";
import Image from "../assets/img/Partners_bg.webp";
import "../styles/partners.css";

const INF = [
  {
    image: difusion,
    texts: "DIFUSION",
    description: `Somos el altavoz para dar a conocer tu negocio o proyecto. Tú decides el cómo y cuándo. Ponemos a tu disposición una comunidad en crecimiento y la plataforma para hacerlo.`,
  },
  {
    image: apoyo,
    texts: "APOYO",
    description: `Juntas sumamos. Comunidad dirigida a mujeres y basada fundamentalmente en el apoyo, la libertad y el respeto. Escuchamos tu propuesta y ponemos a tu disposición nuestras herramientas para alcanzar los objetivos que tú elijas. Entendemos el negocio como una constante evolución y así lo haremos a tu lado.`,
  },
  {
    image: networking,
    texts: "NETWORKING",
    description: `Trabajaremos por conectar con otras personas o iniciativas con las que puedas colaborar. Organizamos reuniones para que puedas establecer contactos y generar sinergias.`,
  },
  {
    image: community,
    texts: "COMUNIDAD",
    description: `Formaras parte de una plataforma dirigida a mujeres y creada para mejorar, evolucionar y crecer.`,
  },
];

const Partners = () => {
  const [show, setShow] = useState(true);

  return (
    <section id="asociados" className="partner-height full-purple-background">
      <div className="partner-container">
        <img
          loading="lazy"
          src={Image}
          alt="partnerimage"
          width="100%"
          height="100%"
        />
      </div>

      <div className="container container-padding">
        <div className="row justify-content-end">
          <div className="col-12 col-xl-5">
            <p className="t-title text-center">Partners</p>
            <div className="card-container w-100">
              {INF.map((e, i) => (
                <PartnerCard
                  key={i}
                  index={i}
                  show={show}
                  setShow={() => setShow(show === i ? false : i)}
                  icon={e.image}
                  text={e.texts}
                  description={e.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const PartnerCard = ({ icon, text, description, index, show, setShow }) => {
  const [rotate, setRotate] = useState(false);

  return (
    <div className="partner-card h-75">
      <motion.div
        className="d-flex justify-content-center card-img-container"
        whileTap={{ scale: 0.9 }}
      >
        <button onClick={() => setShow()} className="button">
          {show === index ? (
            index
          ) : (
            <img
              loading="lazy"
              src={icon}
              alt="iconimage"
              width="110px"
              height="70px"
            />
          )}
          {show === index && (
            <>
              <motion.p
                className="t-pink t-description"
                whileHover={{ scale: 1.05 }}
              >
                {description}
              </motion.p>
              <motion.div
                className="d-flex justify-content-center card-icon-container-up"
                animate={{ rotate: rotate ? 360 : 0 }}
                transition={{ duration: 1 }}
                onClick={() => {
                  setRotate(!rotate);
                }}
              >
                <img
                  loading="lazy"
                  src={check}
                  alt="check"
                  width="86px"
                  height="55px"
                />
              </motion.div>
            </>
          )}
          {show !== index && (
            <>
              <div className="d-flex align-items-center card-texts-container">
                <p className="t-pink text-center w-100 h-75">{text}</p>
              </div>
              <div className="d-flex justify-content-center card-icon-container">
                <img
                  loading="lazy"
                  src={check}
                  alt="check"
                  width="86px"
                  height="55px"
                />
              </div>
            </>
          )}
        </button>
      </motion.div>
    </div>
  );
};

export default Partners;
