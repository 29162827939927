import Inicio from "../assets/img/inicio.webp";
import {
  DownloadButtonApple,
  DownloadButtonGoogle,
} from "../components/Button";
import "../styles/main.css";
const Home = () => {
  return (
    <section id="home" className="main-height green-background">
      <div className="main-img">
        <img
          src={Inicio}
          alt="main-img"
          width="100%"
          height="100%"
        />
      </div>
      <div className="container container-padding">
        <div className="row">
          <div className="col-12 col-lg-6 ">
            <div className=" info d-flex align-content-between flex-wrap">
              <h1 className="text-center text-md-start t-main-title">
                UnitedWom es una aplicación <br /> para compartir
              </h1>
              <p className="text-center text-md-start t-paragraph main-margin">
                Atraer lo que necesitas en cada momento, unir fuerzas, conectar
                experiencias y fluir con tus emociones
              </p>
              <div className="w-100 d-flex gap-4 main-margin">
                <DownloadButtonApple />
                <DownloadButtonGoogle />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
