import { HashLink as Link } from "react-router-hash-link";
import logo from "../assets/logo/logo2.webp";
import { GreenSocialMedia } from "../components/SocialMedia";
import "../styles/footer.css";
const style = {
  textDecoration: "none",
  color: "black",
};
const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="d-flex flex-column justify-content-center align-items-center footer">
          <img
            loading="lazy"
            src={logo}
            alt="logo"
            width="145.11px"
            height="125px"
          />
          <GreenSocialMedia />
          <p className="t-color text-center">
            <Link style={style} to="/privacidadWeb">
              Política de privacidad web
            </Link>{" "}
            |{" "}
            <Link style={style} to="/privacidadApp">
              Política de privacidad app
            </Link>{" "}
            |{" "}
            <Link style={style} to="/cookies">
              Políticas de cookies
            </Link>{" "}
            |{" "}
            <Link style={style} to="/condiciones">
              Condiciones de uso
            </Link>
          </p>
          <p className="t-color">© 2022 UNITEDWOM All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
