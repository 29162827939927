import facebook from "../assets/redes/facebook.webp";
import instgram from "../assets/redes/instagram.webp";
import tiktok from "../assets/redes/tiktok.webp";
import vfacebook from "../assets/redes/v_facebook.webp";
import vinstgram from "../assets/redes/v_instagram.webp";
import vtiktok from "../assets/redes/v_tiktok.webp";
import vyoutube from "../assets/redes/v_youtube.webp";
import youtube from "../assets/redes/youtube.webp";

const links = {
  facebook: "https://www.facebook.com/UnitedWom",
  instagran: "https://www.instagram.com/unitedwom/",
  youtube: "https://www.youtube.com/channel/UCaUqPX8GABQZKMQgiX99cYw",
  tiktok: "https://www.tiktok.com/@unitedwom",
};
const SocialMedia = () => {
  return (
    <div className="social  d-flex">
      <a
        href={links.instagran}
        aria-label="Conoce más acerca de unitedwom en Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={instgram}
          alt="instagram"
          width="20px"
          height="20px"
        />
      </a>
      <a
        href={links.facebook}
        aria-label="Conoce más acerca de unitedwom en Facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={facebook}
          alt="facebook"
          width="20px"
          height="20px"
        />
      </a>
      <a
        href={links.tiktok}
        aria-label="Conoce más acerca de unitedwom en tiktok"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={tiktok}
          alt="tiktok"
          width="20px"
          height="20px"
        />
      </a>
      <a
        href={links.youtube}
        aria-label="Conoce más acerca de unitedwom en Youtube"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={youtube}
          alt="youtube"
          width="20px"
          height="20px"
        />
      </a>
    </div>
  );
};
export const GreenSocialMedia = () => {
  return (
    <div className="social green d-flex">
      <a
        href={links.instagran}
        aria-label="Conoce más acerca de unitedwom en Instagram"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={vinstgram}
          alt="instagram"
          width="50px"
          height="50px"
        />
      </a>
      <a
        href={links.facebook}
        aria-label="Conoce más acerca de unitedwom en Facebook"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={vfacebook}
          alt="facebook"
          width="50px"
          height="50px"
        />
      </a>
      <a
        href={links.tiktok}
        aria-label="Conoce más acerca de unitedwom en tiktok"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={vtiktok}
          alt="tiktok"
          width="50px"
          height="50px"
        />
      </a>
      <a
        href={links.youtube}
        aria-label="Conoce más acerca de unitedwom en Youtube"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          src={vyoutube}
          alt="youtube"
          width="50px"
          height="50px"
        />
      </a>
    </div>
  );
};
export default SocialMedia;
